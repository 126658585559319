const data = [
    {
      text: "Discord server open to the public. Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem, eveniet!",
      date: "Dec 20 2021",
      category: {
        tag: "Welcome new Slumlords",
        color: "#287743"
      },
      link: {
        url:
          "https://discord.gg/KedbrupzRE",
        text: "Join Discord"
      }
    },
    {
      text: "Criando um Readme sensacional para o seu Github",
      date: "Janurary 10 2022",
      category: {
        tag: "Phase 1",
        color: "#D4AF37"
      },
      link: {
        url:
          "https://www.slumlords.io/",
        text: "Join"
      }
    },
    {
      text: "Criando um Readme sensacional para o seu Github",
      date: "Feburary 1 2022",
      category: {
        tag: "Phase2",
        color: "#D4AF37"
      },
      link: {
        url:
          "https://www.slumlords.io/",
        text: "Read more"
      }
    },
    {
      text: "Criando um Readme sensacional para o seu Github",
      date: "Feburary 10 2022",
      category: {
        tag: "Phase 3",
        color: "#287743"
      },
      link: {
        url:
          "https://www.slumlords.io/",
        text: "Read more"
      }
    }
  ];
  
  export default data;
  